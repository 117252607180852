import React, { useEffect, useState } from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import DropDownArrow from "@mui/icons-material/ArrowDropDown"
import DropUpArrow from "@mui/icons-material/ArrowDropUp"
import Button from "@mui/material/Button"

const AccordianHeader = (props) => {
  return (
    <Button onClick={props.onClick} className={"new-blue-link"} color="primary">
      {props.title}
      {props.expanded ? <DropUpArrow /> : <DropDownArrow />}
    </Button>
  )
}

const StyledLink = (props) => {
  return (
    <div className="singleItemList">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 27" fill="none">
        <path
          d="M5.49999 0C4.82786 -3.74142e-09 4.16231 0.132395 3.54135 0.389625C2.92039 0.646855 2.35618 1.02388 1.88093 1.49917C1.40569 1.97447 1.02872 2.53872 0.771559 3.15971C0.514395 3.7807 0.38207 4.44626 0.382141 5.11839C0.382103 6.35884 0.832587 7.55708 1.64981 8.49027C2.46704 9.42346 3.59538 10.0281 4.82499 10.1917V26.325C4.82499 26.504 4.89611 26.6757 5.02269 26.8023C5.14928 26.9289 5.32097 27 5.49999 27C5.67901 27 5.8507 26.9289 5.97729 26.8023C6.10388 26.6757 6.17499 26.504 6.17499 26.325V10.1914C7.40456 10.0278 8.53286 9.42324 9.35008 8.4901C10.1673 7.55697 10.6178 6.35879 10.6178 5.11839C10.6179 4.44626 10.4856 3.7807 10.2284 3.15971C9.97126 2.53872 9.59429 1.97447 9.11905 1.49917C8.64381 1.02388 8.07959 0.646855 7.45863 0.389625C6.83767 0.132395 6.17212 -3.74142e-09 5.49999 0V0ZM4.19535 2.30823C4.40621 2.30823 4.61501 2.34976 4.80982 2.43046C5.00463 2.51115 5.18164 2.62942 5.33075 2.77853C5.47985 2.92763 5.59812 3.10464 5.67882 3.29945C5.75951 3.49426 5.80104 3.70306 5.80104 3.91392C5.80104 4.12478 5.75951 4.33358 5.67882 4.52839C5.59812 4.7232 5.47985 4.90021 5.33075 5.04931C5.18164 5.19842 5.00463 5.31669 4.80982 5.39738C4.61501 5.47808 4.40621 5.51961 4.19535 5.51961C3.7695 5.51961 3.36108 5.35044 3.05996 5.04931C2.75883 4.74819 2.58966 4.33978 2.58966 3.91392C2.58966 3.48806 2.75883 3.07965 3.05996 2.77853C3.36108 2.4774 3.7695 2.30823 4.19535 2.30823Z"
          fill="#16388E"
        />
      </svg>
      <Link to={props.to} key={"link-for-" + props.title}>
        {props.title}
      </Link>
    </div>
  )
}

export default function (props) {
  const { serviceSlug, stateSlug, citySlug, serviceAreas } = props
  const [openSection, setOpenSection] = useState("")
  const [otherVisible, setOtherVisible] = useState(true)

  useEffect(() => {
    if (!!serviceAreas && serviceAreas.length > 0) {
      setOpenSection(serviceAreas[0].id)
    }
  }, [])
  if (!!serviceAreas) {
    return (
      <div className="faqWrapper serviceAreas">
        {serviceAreas.map((service, index) => {
          const expanded = openSection === service.id
          return (
            <div className="faqList">
              <div className="faqItems" key={service.serviceName + "-area"}>
                <AccordianHeader
                  title={service.serviceName}
                  onClick={() => {
                    if (expanded) {
                      setOpenSection("")
                    } else {
                      setOpenSection(service.id)
                    }
                  }}
                  expanded={expanded}
                />
                {expanded && (
                  <div className="serviceAreasContent">
                    {service.locations.map((location, index) => {
                      let url = `/${location.serviceSlug}/${location.stateSlug}/`
                      if (location.citySlug) {
                        url = url + `/${location.citySlug}`
                      }
                      return (
                        <StyledLink
                          to={url}
                          title={location.locationName}
                          key={"link-for-" + location.locationName}
                        />
                      )
                    })}
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
    )
  } else if (serviceSlug && stateSlug) {
    return (
      <StaticQuery
        query={graphql`
          query ServiceAreaQuery {
            allContentfulServiceHub {
              nodes {
                id
                locations {
                  locationName
                  serviceSlug
                  stateSlug
                  citySlug
                }
              }
            }
          }
        `}
        render={(data) => {
          const service = data.allContentfulServiceHub.nodes.find(
            (svc) => svc.serviceSlug === serviceSlug
          )
          if (!service) {
            return null
          }
          const { locations } = service
          const otherLocations = locations.filter((loc) => {
            if (citySlug) {
              return loc.citySlug !== citySlug
            }
            return loc.citySlug !== null
          })
          if (otherLocations.length === 0) {
            return null
          }
          return (
            <div className="faqWrapper serviceAreas singleAreas">
              <div className="faqList">
                <div className="faqItems">
                  <AccordianHeader
                    title={"Our Other Coverage Areas"}
                    onClick={() => {
                      setOtherVisible(!otherVisible)
                    }}
                    expanded={otherVisible}
                  />
                  {otherVisible && (
                    <div className="serviceAreasContent">
                      {otherLocations &&
                        otherLocations.map((location, index) => {
                          let url = `/${location.serviceSlug}/${location.stateSlug}`
                          if (location.citySlug) {
                            url = url + `/${location.citySlug}`
                          }
                          return (
                            <StyledLink
                              to={url}
                              title={location.locationName}
                              key={"link-for-" + location.locationName}
                            />
                          )
                        })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        }}
      />
    )
  }
  return null
}
