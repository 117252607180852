import React, { useState } from "react"
import Layout from "../components/layout"
import "react-h5-audio-player/lib/styles.css"
import FaqComponent from "../components/faq/faq-component"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"
import BreadCrumbs from "../components/bread-crumbs"
import "./services.scss"
import {
  isValidContentfulImage,
  isValidSectionField,
} from "../common/utils/CommonUtils"
import Button from "@mui/material/Button"
import ArrowForwardIcon from "@mui/icons-material/Forward"
import ArrowNavigationIcon from "@mui/icons-material/NearMe"
import ArrowForwardThinIcon from "@mui/icons-material/ArrowForward"
import ServiceAreas from "./../components/service-areas"
import Loadable from "@loadable/component"
const LoadableCarousel = Loadable(() => import("./../components/owl-carousel"))
const breadCrumbsList = [
  {
    title: "Home",
    link: "/",
  },
]

export default function Locations(props) {
  const [activeFaqIndex, setActiveFaqIndex] = useState(-1)
  const { data } = props
  const pageData = data.allContentfulLocationHub.edges[0].node
  const {
    visibleServices,
    serviceAreasHeading,
    serviceAreasDescription,
    downloadAppText,
    serviceAreas,
    topSection,
    letterToLovedOnes,
    faqHeading,
    faqItems,
    servicesHeading,
    seo,
  } = pageData
  return (
    <Layout location={props.location}>
      <SEO
        title={seo?.pageTitle}
        description={seo?.metaDescription?.metaDescription}
      />
      <BreadCrumbs
        currentPage={"Community Payment"}
        breadCrumbsList={breadCrumbsList}
      />
      <div className="get-started-home-wrapper">
        <div className="get-started-home-inner">
          <div className="get-started-text-wrap">
            <img
              className="get-started-home-pattern"
              src={require("../assets/images/home-bg-circles.png")}
              alt="Pattern"
            />
            {isValidSectionField(topSection, "title") && (
              <h1 className="blackHeading2">{topSection.title}</h1>
            )}
            {isValidSectionField(topSection, "subtitle") && (
              <p
                className="subTitle"
                dangerouslySetInnerHTML={{ __html: topSection.subtitle }}
              ></p>
            )}
            {isValidSectionField(topSection, "description") && (
              <p
                className="parah"
                dangerouslySetInnerHTML={{
                  __html: topSection.description.description,
                }}
              ></p>
            )}
            <Button
              onClick={() => {}}
              href={"/get-started"}
              className="blue-arrow-btn"
              color="primary"
              endIcon={<ArrowForwardIcon />}
            >
              <span className="button-label">Get Started</span>
            </Button>
          </div>
          <div className="get-started-anim-wrap">
            {isValidContentfulImage(topSection.imageOrVideo) && (
              <img
                className="get-started-home-hero-img"
                src={topSection.imageOrVideo?.file?.url}
                alt="providerImg"
              />
            )}
          </div>
        </div>
      </div>

      <div className="new-home-features">
        <div className="serviceInfoWrapper">
          <div className="serviceInfoText">
            <h2 className="interested-heading">{serviceAreasHeading}</h2>
            <p
              className="parah2 mb50"
              dangerouslySetInnerHTML={{
                __html: serviceAreasDescription.serviceAreasDescription.replace(
                  /\n/g,
                  "<br/>"
                ),
              }}
            />
          </div>
        </div>

        <ServiceAreas serviceAreas={serviceAreas} />

        <div className="serviceInfoWrapper">
          <div className="serviceInfoText">
            <h2 className="interested-heading">{servicesHeading}</h2>
          </div>
        </div>
        <div className="rehab-process servicesOffer carouselOffer">
          <div className="rehabProcessList">
            <LoadableCarousel visibleServices={visibleServices} />
          </div>
        </div>

        <div className="serviceFeatureWrapper">
          <div className="new-home-features-inner">
            {downloadAppText && (
              <div>
                <div className="something-main-wrapper">
                  <div className="something-inner">
                    <h2 className="something-head">{downloadAppText}</h2>
                    <Button
                      className="blue-arrow-btn"
                      href="https://apps.apple.com/us/app/confidant-health/id1478727129"
                      endIcon={<ArrowNavigationIcon />}
                    >
                      <span className="button-label">DOWNLOAD OUR APP</span>
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {letterToLovedOnes && (
              <div className="new-home-letter-one">
                <div className="new-home-letter-one-inner">
                  {isValidContentfulImage(letterToLovedOnes.image) && (
                    <img
                      className="new-feature-image"
                      src={letterToLovedOnes.image.file.url}
                      alt=" Frame"
                    />
                  )}
                  <h2 className="home-letter-one-heading">
                    {letterToLovedOnes.heading}
                  </h2>
                  <p className="home-letter-one-para">
                    {letterToLovedOnes.description.description}
                  </p>
                  <Button
                    href="/letter-to-loved-ones/"
                    className="new-blue-link"
                    color="primary"
                  >
                    Read the Letter <ArrowForwardThinIcon />
                  </Button>
                </div>
              </div>
            )}
            <div className="faqWrapper">
              {faqHeading !== null && (
                <h2 className="interested-heading">{faqHeading}</h2>
              )}
              <div className="faqList">
                {faqItems &&
                  faqItems.map((faqItem, index) => {
                    const active = index === activeFaqIndex
                    return (
                      <div className="faqItems" key={"faq-item-" + index}>
                        <Button
                          onClick={() => {
                            setActiveFaqIndex(index)
                          }}
                          className={
                            active ? "new-blue-link-active" : "new-blue-link"
                          }
                          color="primary"
                        >
                          {faqItem.title.title}
                        </Button>
                        {active && (
                          <div className="parah">
                            {documentToReactComponents(
                              JSON.parse(faqItem.description.raw)
                            )}
                          </div>
                        )}
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query getLocationHubPage {
    allContentfulLocationHub {
      edges {
        node {
          topSection {
            title
            subtitle
            description {
              description
            }
            imageOrVideo {
              file {
                url
              }
            }
          }
          serviceAreasHeading
          serviceAreasDescription {
            serviceAreasDescription
          }
          serviceAreas {
            id
            serviceName
            serviceSlug
            locations {
              locationName
              serviceSlug
              stateSlug
              citySlug
            }
          }
          servicesHeading
          visibleServices {
            serviceName
            serviceIcon {
              file {
                url
              }
              gatsbyImageData
            }
            slug
            shortDescription
          }
          downloadAppText
          letterToLovedOnes {
            description {
              description
            }
            image {
              file {
                url
              }
              gatsbyImageData
            }
            heading
          }
          faqHeading
          faqItems {
            description {
              raw
            }
            title {
              title
            }
          }
          seo {
            pageTitle
            metaDescription {
              metaDescription
            }
          }
        }
      }
    }
  }
`
