/**
 * Created by Ishaq on 3/2/2019.
 */
import React, { Component } from "react"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

class FaqComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isExpanded: this.props.atIndex === 0,
    }
  }

  calculateExpansion(fromDidMount) {
    this.setState({
      isExpanded: !this.state.isExpanded,
    })
  }

  render() {
    return (
      <Accordion
        className="single-accordion"
        expanded={this.state.isExpanded}
        onChange={() => this.calculateExpansion(false)}
      >
        <AccordionSummary
          className="accordion-summary"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
        >
          <p className="acco-title">{this.props.faqItem.title.title}</p>
        </AccordionSummary>
        <AccordionDetails className="acco-body">
          {documentToReactComponents(
            JSON.parse(this.props.faqItem.description.raw)
          )}
        </AccordionDetails>
      </Accordion>
    )
  }
}

export default FaqComponent
